import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../CustomButton";
import { Loader } from "../Loader";

 export const Preview = ({ url,goBack }) => {
    const [isLoading, setLoading] = useState(true);
    const { t } = useTranslation();

    console.log(isLoading)
    return (
        <div className="container-fluid">
            {isLoading && <Loader/>}
                <iframe type="text/html" onLoad={()=>{setLoading(false)}}  src="https://gdti-verification.dev.k8s.sandboxaddis.com/certificate/vc/5f225c4b-f097-41cf-9ca0-79e863c96389"
                    style={{ width: '100%', height: '500px' }}></iframe>       
                     <CustomButton className="blue-btn m-3  " onClick={goBack}>Back</CustomButton>
            </div>
            )
}
