import React, { useEffect, useState } from "react";
import "./index.css";
import CertificateValidImg from "../../assets/img/certificate-valid.svg";
import CertificateInValidImg from "../../assets/img/certificate-invalid.svg";
import { CustomButton } from "../CustomButton";
import { useDispatch } from "react-redux";
import { addEventAction, EVENT_TYPES } from "../../redux/reducers/events";
import { Loader } from "../Loader";
import { useTranslation } from "react-i18next";
import { CertificateDetail } from "./detail";
import { Preview } from "./preview";
import { verifyCredentials } from "../../certificateSigner/signer";

export const CertificateStatus = ({ certificateData, goBack }) => {
    const [isLoading, setLoading] = useState(true);
    const [showDetail, setShowDetail] = useState(true);
    const [isValid, setValid] = useState(false);
    const [isRevoked, setRevoked] = useState(false);
    const [data, setData] = useState({});
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        setShowDetail(false);
        async function verifyData() {
            try {
                const signedJSON = JSON.parse(certificateData);
                const result = await verifyCredentials(signedJSON);
                if (result.verified) {
                    setValid(true);
                     await fetch(signedJSON.credentialSubject.infoUrl).then(res => res.json()).then((result) => {
                        signedJSON.credentialSubject.infoUrl= result.fileURI;
                     }).catch((error)=>{
                         console.log(error);
                     });
                    setData(signedJSON.credentialSubject);
                    setRevoked(false);
                    dispatch(addEventAction({
                        type: EVENT_TYPES.VALID_VERIFICATION,
                        extra: signedJSON.credentialSubject
                    }));
                    setLoading(false);
                    return
                }
                dispatch(addEventAction({ type: EVENT_TYPES.INVALID_VERIFICATION, extra: signedJSON.credentialSubject }));
                setValid(false);
                setLoading(false);
            } catch (e) {
                console.log('Invalid data', e);
                setValid(false);
                dispatch(addEventAction({ type: EVENT_TYPES.INVALID_VERIFICATION, extra: certificateData }));
            } finally {
                setLoading(false);
            }
        }
        verifyData()

    }, []);
    return (
         isLoading  ?( showDetail ? <Preview url={data.infoUrl} goBack={() => {
            setShowDetail(false);
            setLoading(false);
        }
        } />:<Loader /> ):
            <div className="certificate-status-wrapper">
                <img src={isValid ? CertificateValidImg : CertificateInValidImg} alt={""}
                    className="certificate-status-image" />
                <h3 className="certificate-status">
                    {
                        isValid ? t('verifyCertificate.validStatus') : (isRevoked ? t('verifyCertificate.revokedStatus') : t('verifyCertificate.invalidStatus'))
                    }
                </h3>
                {
                    isRevoked && <h4>{t('verifyCertificate.revokeText')}</h4>
                }
                {
                    !showDetail && isValid &&
                    <table className="mt-3 table table-sm certificate-data">
                        <tbody>
                            {displayData(data)}
                            <tr>
                                <td className="font-weight-bolder">Extra Info :</td>
                                <td>
                                    <a
                                     href={data.infoUrl} 
                                     target="_blank">document preview</a>
                                    {/* <CustomButton isLink={true} onClick={() => { setLoading(true);  setShowDetail(true) }} >click for more ...</CustomButton> */}
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                }
                <CustomButton className="blue-btn m-3  " onClick={goBack}>{t('verifyCertificate.verifyAnotherCertificate')}</CustomButton>
            </div>
    )
};
export const toTextCase = (camelCase) => {
    const result = camelCase.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);

}
export const displayData = (data) => {
    return Object.keys(data).map((key, index) => {
        if (key !== 'infoUrl' && key !== 'id')
            return (
                <tr key={index}>
                    <td className="font-weight-bolder">
                        {toTextCase(key)} : </td>
                    <td className="">{(data[key] && typeof data[key] === 'object') ? displayData(data[key]) : data[key]}</td>
                </tr>
            )
    })
}