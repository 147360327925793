// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_navbar__jhaJN {\n    border-top: 1px solid #C1CFD933;\n    padding-left: 5px !important;\n    padding-right: 5px !important;\n    position: fixed !important;\n    bottom: 0 !important;\n    width: 100%;\n    z-index: 100;\n}\n\n.Footer_nav-link__3g1Jw {\n    color: #646D82 !important;\n    font-size: 12px;\n    padding-left: 1rem !important;\n    padding-right: 1rem !important;\n}\n\n.Footer_footer__1w0lV{\n    border-top: 1px solid #C1CFD933;\n    padding: 30px !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,4BAA4B;IAC5B,6BAA6B;IAC7B,0BAA0B;IAC1B,oBAAoB;IACpB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,+BAA+B;IAC/B,wBAAwB;AAC5B","sourcesContent":[".navbar {\n    border-top: 1px solid #C1CFD933;\n    padding-left: 5px !important;\n    padding-right: 5px !important;\n    position: fixed !important;\n    bottom: 0 !important;\n    width: 100%;\n    z-index: 100;\n}\n\n.nav-link {\n    color: #646D82 !important;\n    font-size: 12px;\n    padding-left: 1rem !important;\n    padding-right: 1rem !important;\n}\n\n.footer{\n    border-top: 1px solid #C1CFD933;\n    padding: 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "Footer_navbar__jhaJN",
	"nav-link": "Footer_nav-link__3g1Jw",
	"footer": "Footer_footer__1w0lV"
};
export default ___CSS_LOADER_EXPORT___;
