import React, { useEffect, useState } from "react";
import "./index.css";
import axios from "axios";
import { Loader } from "../Loader";
import { displayData } from ".";
export const CertificateDetail = ({ url, goBack }) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});
    useEffect(() => {
        setLoading(true);
        async function viewDetail() {
            try {
                const response = await axios.get(url);
                if (response.status === 200 && response.data) {
                    setData(response.data)
                }
            } catch (e) {
                console.log('Invalid data', e);
            } finally {
                setLoading(false);
            }
        }
        viewDetail()
    }, []);
    return (
        isLoading ? <Loader /> :
            <div>
                {
                    <table className="mt-3 table table-sm certificate-data">
                        <tbody>
                            {displayData(data.data)}
                            {displayData(data.additionalData ? data.additionalData : {})}
                        </tbody>
                    </table>
                }
            </div>
    )
};


