import {formatDate} from "./utils/CustomDate";

export const CERTIFICATE_FILE = "certificate.json";

export const CertificateDetailsPaths = {
  "Certificate Type": {
    path: ["credentialSubject", "type"],
    format: (data) => (data)
  },
  "Valid  Until": {
    path: ["credentialSubject", "validUntil"],
    format: (data) => (data)
  },
  "Application Number": {
    path: ["credentialSubject", "applicationNumber"],
    format: (data) => (data)
  },
  "Registration Number": {
    path: ["credentialSubject", "registrationNo"],
    format: (data) => (data)
  },
  "Registration Type": {
    path: ["credentialSubject", "typeOfRegistration"],
    format: (data) => (data)
  }, 
  "Performa Invoice Number": {
    path: ["credentialSubject", "performaInvoiceNumber"],
    format: (data) => (data),
    optional: true
  },
  "Amount": {
    path: ["credentialSubject", "amount"],
    format: (data) => (data)
  },
  "Agent Name": {
    path: ["credentialSubject", "agentName"],
    format: (data) => (data)
  },
  "TIN Number": {
    path: ["credentialSubject", "agentTin"],
    format: (data) => (data)
  },
  "Product Name": {
    path: ["credentialSubject", "productName"],
    format: (data) => (data)
  },
  "Products LIst": {
    path: ["credentialSubject", "products"],
    format: (data) => (      (typeof data === 'object')? JSON.stringify(data):data
    )
  },
  "Supplier Name": {
    path: ["credentialSubject", "supplierName"],
    format: (data) => (      (typeof data === 'object')? JSON.stringify(data):data
    )
  },
  "Manufacturer": {
    path: ["credentialSubject", "manufacturer"],
    format: (data) => (
      (typeof data === 'object')? JSON.stringify(data):data
      )
  },
  "Approval Date": {
    path: ["credentialSubject", "approvalDate"],
    format: (data) => (formatDate(data))
  },
  "Date of Issue": {
    path: ["credentialSubject", "decisionDate"],
    format: (data) => (formatDate(data))
  }
};
